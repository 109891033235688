/*global $*/

class Guides {
    constructor() {
        var _this = this;
        $('#guide_type').on("change", function() {
            _this.fetchGuidesBlock('getCategoryGuides');
        });
        $('#product_category').on("change", function() {
            _this.fetchGuidesBlock('getCategoryGuides');
        });
        $('#product_id').on("change", function() {
            _this.fetchGuidesBlock('getCategoryGuides');
        });
        
    }

    fetchGuidesBlock(url) {
        
        var typeId = $('#guide_type').find(":selected").val();
        var productCategoryId = $('#product_category').find(":selected").val();
        var productId = $('#product_id').find(":selected").val();
    
        $.ajax({
            type: 'get',
            url: '/' + url,
            dataType: 'html',
            data: { type_id: typeId, product_category_id: productCategoryId, product_id:productId},
            processData: true,
            success: function(result) {
                $("#all_events_list").html('');
                $("#all_events_list").append(result);
            },
            error: function() {
                $("#all_events_list").html('');
                var errorHtml = '';
                errorHtml += '<div class="col-12 col-md-12 itemFound">';
                errorHtml += '<span>Sorry! No relevant content found</span>';
                errorHtml += '</div>';
                $("#all_events_list").append(errorHtml);
            }
        });
    }
}

export default Guides;