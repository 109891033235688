/*global $*/

class Products {
    constructor() {
        var _this = this;
        // var webpath = window.location.pathname;
        // var pagetype = webpath.split('/');
        // var entryproducttype = pagetype[1];
        $('.eventcategory').on("click", function() {
            $(".category_images").html('');
            var categoryId = $(this).attr("data-id");
            var entryId = $(this).attr("data-enid");
            _this.fetchProductImage(categoryId, entryId, 'getCategoryImages');
        });
        // var categoryId = $('#currColourCategoryId').val();
        // _this.fetchProductImage(categoryId, 'getCategoryImages');
    }

    fetchProductImage(categoryId, entryId, url) {
        $('.spans').show();
        $.ajax({
            type: 'get',
            url: '/' + url,
            dataType: 'html',
            data: { categoryId: categoryId, entryId: entryId },
            processData: true,
            success: function(result) {
                $('.spans').hide();
                $("#all_events_list").append(result);
                $('.product-gallery-slider').slick({
                    autoplay: true,
                    arrows: true,
                    dots: false,
                    slidesToShow: 1,
                    speed: 1000,
                    autoplaySpeed: 4000,
                    slidesToScroll: 1,
                    sNavFor: '.product-gallery-slide-nav'
                });
        
                $('.product-gallery-slide-nav').slick({
                    slidesToShow: 5,
                    slidesToScroll: 1,
                    asNavFor: '.product-gallery-slider',
                    dots: false,
                    focusOnSelect: true,
                    arrows: false,
                });
            },
            error: function() {
                $('.spans').hide();
                $("#all_events_list").html('');
                var errorHtml = '';
                errorHtml += '<div class="col-12 col-md-12 itemFound">';
                errorHtml += '<span>Sorry! No relevant content found for this category</span>';
                errorHtml += '</div>';
                $("#all_events_list").append(errorHtml);
            }
        });
    }
}

export default Products;