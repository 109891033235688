/*global $ formidable*/

class Form
{
    constructor(id, messages ){
        this.form = $('#'+id);
        
        if (this.form.length) {
            this.form.find('.datepicker').datepicker({
                format: 'dd/mm/yyyy',
            });
            this.initFormSubmit(messages,id);
        }
    }

    initFormSubmit(messages, id) {
        if (id == 'brochure_form')
        {
            var ckval = this.makeAuthId(10);
            this.createCookie('brochure', ckval, 1);
        }
        this.form.off('submit');
        this.form.submit(function(e) {
            e.preventDefault();
            $('.contact-us-success').hide();
            $('.contact-us-error').remove();
            if (formidable.validateForm(this, e)) {
                $(this).find('[type=submit]').attr('disabled', true);
                let url = $(this).attr('action');
                let form_id = $(this).attr('id');
                let form = $(this);
                let formData = new FormData(this);
                $.ajax({
                    url: url,
                    data: formData,
                    contentType: false,
                    processData: false,
                    method: 'POST',
                    dataType: 'JSON'
                }).done((data) => {
                    if (data.success) {
                        // let success = $('.contact-us-success');
                        // success.fadeIn();
                        if(form_id == 'get_a_quote'){
                            window.location.href = "/free-quotation/thankyou";
                        }
                        else if(form_id == 'get_a_quote_common'){
                            window.location.href = "/free-quotations/thankyou";
                        }
                        else if(form_id == 'book_an_appointment'){
                            window.location.href = "/book-an-appointment/thankyou";
                        }
                        else if(form_id == 'book_an_appointment_common'){
                            window.location.href = "/book-appointment/thankyou";
                        }
                        else if(form_id == 'special_offers'){
                            
                            window.location.href = "/special-offers/quote/thankyou";
                        }
                        else if(form_id == 'brochure_form'){
                            window.location.href = "brochure/thankyou/";
                        }
                        else{
                            window.location.href = "/general-enquiry/thankyou";
                        }

                        // form.trigger('reset');
                        // $('html, body').animate({ scrollTop: success.offset().top - 170 }, 500);
                    }
                }).fail((response) => {
                    for (let i in response.responseJSON.errors) {
                        let errors = response.responseJSON.errors[i];
                        for (let j in errors) {
                            $('<div class="contact-us-error alert alert-danger">'+errors[j]+'</div>').appendTo(messages);
                        }
                        $('html, body').animate({ scrollTop: messages.offset().top - 170 }, 500);
                    }
                }).always(() => {
                    window.googleV3Captcha.getCaptcha(form.attr('id')).then(function (token) {
                        form.find('[name=_recaptcha_response]').val(token);
                        form.find('[type=submit]').attr('disabled', false);
                    });
                    //$(this).find('[type=submit]').attr('disabled', false);
                });
            }
        });
    }

    makeAuthId(length) {
        var result           = '';
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    createCookie(name, value, days) {
        var expires;
        if (days) {
            var date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            expires = "; expires=" + date.toGMTString();
        } else {
            expires = "";
        }
        document.cookie = encodeURIComponent(name) + "=" + encodeURIComponent(value) + expires + "; path=/";
    }
}

export default Form;
