/*global $*/

import Home from './components/home';
import Slick from './components/slick';
import Form from './components/form';
import Teams from './components/teams';
import Products from './components/products';
import Guides from './components/guides';

window.Form = Form;
window.Teams = Teams;
window.Products = Products;
window.Guides = Guides;

$(function() {
    window.Home = new Home();
    new Slick();
});
