/*global $*/

class Home
{
    constructor()
    {
        this.getBootstrap();
        this.initTooltips($('[data-bs-toggle="tooltip"]'));
        this.initAddSpan();
        this.initStickyMenu();
        this.initVimeoVideo();
        this.initMainMenu();
        this.initMatchHeight();
        this.initFlotingMenu();
        this.initHomeSlider();
        this.hideSplash();
        this.loadinsta();
        this.initPopups();

    }

    initPopups()
    {
        if ($("#popupModal").length) {
            const popupBody = $("#popupModal .modal-body");
            const popupId = $("#popupModal").data('popup-id');

            popupBody.html("");

            $.ajax({
                type: 'get',
                url: '/ajax/getPopupDetails',
                dataType: 'html',
                data: { popupId: popupId },
                processData: true,
                success: function(result) {
                    popupBody.html(result);
                }
            });
            
            $('#popupModal').modal({show:true});
        }
    }

    loadinsta()
    {
        var _this = this;
        $('.noadmoreinstabtn').on('click', function(){
            let instaafter = $(this).data('after');
            $('.insta-roller').removeClass('d-none');
            $.ajax({
                type: 'get',
                url: '/getInstaMedia',
                dataType: 'html',
                data: { limit: 8, after: instaafter},
                processData: true,
                success: function(result) {
                    $(".instabtns").remove();
                    $(".feedappend").append(result);
                    _this.loadinsta();
                },
                error: function() {
                }
            });
        })
    }

    getBootstrap() {
        if (!this.bootstrap) {
            this.bootstrap = import(
            /* webpackChunkName: "bootstrap" */ "bootstrap"
            );
        }
        return this.bootstrap;
    }

    hideSplash()
    {
        $('#splash').fadeOut(200);
    }

    initHomeSlider()
    {
        $('.slick-carousel').slick({
            autoplay: true,
            dots: true,
            arrows: false,
            fade: true,
            cssEase: 'linear',
            speed: 1500,
            autoplaySpeed: 4000,
            pauseOnHover: false,
        });
    }
    initTooltips($elems) {
        if ($elems.length) {
            this.getBootstrap().then((bootstrap) => {
                $.each($elems, function (i, elem) {
                    new bootstrap.Tooltip(elem);
                });
            });
        }
    }
    initAddSpan()
    {
        $(".form-check-input").after("<span class='ck-mark'></span>");
        $(".form-check").wrapInner('<label></label>');
        $('.article').readmore({
            speed: 75, //Açılma Hızı
            collapsedHeight: 143, // 100px sonra yazının kesileceğini belirtir.
            moreLink: '<a class="txt-open" href="#">Show more <span></span></a>', // açma linki yazısı
            lessLink: '<a class="less-btn" href="#">Show Less <span></span></a>', // kapatma linki yazısı
        });
    }
    initMatchHeight(){
        $(function() {
            $('.res-cnt h3').matchHeight({
                byRow: true,
                property: 'height',
                target: null,
                remove: false
            });
            $('.resource-ttlH').matchHeight({
                byRow: true,
                property: 'height',
                target: null,
                remove: false
            });
            $('.brochure-title').matchHeight({
                byRow: true,
                property: 'height',
                target: null,
                remove: false
            });
        });
    }
    
    initStickyMenu() {
        var stickyNav = function() {
            var scrollTop = $(window).scrollTop() + 30;
            if (scrollTop > 250) {
                $('.sticky').addClass('fixed');
            } else {
                $('.sticky').removeClass('fixed');
            }
        };
        stickyNav();
        $(window).scroll(function() {
            stickyNav();
        });

        $(".navbar-toggler").click(function() {
            $('body').toggleClass("menuOpen");
        });
    }
    initVimeoVideo() {
        $(document).on('ready', function() {
            var isVideoAutoplay = true;
            var isAuidoMuted = true;
            if (isVideoAutoplay === true) {
                $('#vi-video-1-container').attr('data-video-is-playing', true);
                $('#vi-banner-video').vimeo('play');
                $(".video-control.js-video-control").removeClass('paused').addClass('playing');
            } else {
                $('#vi-video-1-container').attr('data-video-is-playing', false);
                $('#vi-banner-video').vimeo('pause');
                $('.video-control.js-video-control.paused').addClass("video-control-show");
            }
            if (isAuidoMuted === true) {
                $('#vi-video-1-container').attr('data-audio-volume', 0);
                $("#vi-banner-video").vimeo("setVolume", 0);
                $(".audio-control.js-audio-control").removeClass('unmuted').addClass('muted');
            } else {
                $('#vi-video-1-container').attr('data-audio-volume', 1);
                $("#vi-banner-video").vimeo("setVolume", 1);
            }
        });

        function playVideo() {
            let videoStatus = $("#vi-video-1-container").attr('data-video-is-playing');
            if (videoStatus == 'true') {
                // Pause the video
                $('#vi-banner-video').vimeo('pause');
                $(".video-control.js-video-control").removeClass('playing').addClass('paused');
                $('#vi-video-1-container').attr('data-video-is-playing', false);
                $('.video-control.js-video-control.paused').addClass("video-control-show");
            } else if (videoStatus == 'false') {
                // Play the video
                $('#vi-banner-video').vimeo('play');
                $(".video-control.js-video-control").removeClass('paused').addClass('playing');
                $('#vi-video-1-container').attr('data-video-is-playing', true);
                $('.video-control.js-video-control').css('opacity', '');
                $('.video-control.js-video-control.playing').removeClass("video-control-show");
            }
        }

        function muteAudio() {
            // audioStatus = $("#vi-video-1-container").attr('data-audio-volume');
            // if (audioStatus == 0) {
            //     // Mute the audio
            //     $('#vi-video-1-container').attr('data-audio-volume', 1);
            //     $("#vi-banner-video").vimeo("setVolume", 1);
            //     $(".audio-control.js-audio-control").removeClass('muted').addClass('unmuted');
            // } else if (audioStatus == 1) {
            //     // Play the audio
            //     $('#vi-video-1-container').attr('data-audio-volume', 0);
            //     $("#vi-banner-video").vimeo("setVolume", 0);
            //     $(".audio-control.js-audio-control").removeClass('unmuted').addClass('muted');
            // }
        }

        $(document).on('click', '.js-video-control', function(e) {
            playVideo($(this));
            e.preventDefault();
        });
        $(document).on('click', '.js-audio-control', function(e) {
            muteAudio($(this));
            e.preventDefault();
        });
    }
    initMainMenu() {
        $(document).ready(function () {
            $('.navbar .dropdown-toggle').on('click', function (e) {
                var $el = $(this);
                var $parent = $el.offsetParent(".dropdown-menu");
        
                if (!$parent.parent().hasClass('navbar-nav')) {
                    if ($parent.hasClass('show')) {
                        $parent.removeClass('show');
                        $el.next().removeClass('show');
                    } else {
                        $parent.parent().find('.show').removeClass('show');
                        $parent.addClass('show');
                        $el.next().addClass('show');
                    }
                    e.preventDefault();
                    e.stopPropagation();
                }
            });
        });
    } 
    initFlotingMenu(){
        if (!$('#floatingmenu').length) {
            return;
        }
        var trigger = $('#floatingmenu-trigger');
        var float =  $('#floatingmenu');

        $(document).scroll(function() {  
            var floatingMenuTop = trigger.offset().top - 20;
            var windowTop = $(window).scrollTop();
            if (windowTop >= floatingMenuTop) {
                float.addClass('stickybtn');
            }
            else {
                float.removeClass('stickybtn');
            }  
        });
    } 
}

export default Home;