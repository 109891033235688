/*global $*/

class Slick {
    constructor() {
        this.initHeaderBanner();
        // this.makeProjectSliderCardEqualHeight();
    }
    initHeaderBanner() {
        $('.latest-news-listing').slick({
            autoplay: true,
            arrows: true,
            slidesToShow: 3,
            speed: 1000,
            autoplaySpeed: 4000,
            slidesToScroll: 1,
            responsive: [{
                breakpoint: 767,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
            ]
        });

        $('.testimonial-slider').slick({
            autoplay: false,
            arrows: true,
            slidesToShow: 1,
            speed: 1000,
            autoplaySpeed: 4000,
            slidesToScroll: 1
        });
        $('.slick-elem').slick({
            autoplay: true,
            arrows: true,
            slidesToShow: 1,
            speed: 1000,
            autoplaySpeed: 4000,
            slidesToScroll: 1
        });

        $('.main-slider').slick({
            autoplay: true,
            arrows: true,
            dots: true,
            slidesToShow: 1,
            speed: 1000,
            autoplaySpeed: 4000,
            slidesToScroll: 1,
            asNavFor: '.slider-nav'
        });

        $('.slider-nav').slick({
            slidesToShow: 4,
            slidesToScroll: 1,
            vertical: true,
            asNavFor: '.main-slider',
            dots: false,
            focusOnSelect: true,
            verticalSwiping: true,
            arrows: false,
            responsive: [{
                breakpoint: 767,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    vertical: false,
                }
            }]
        });

        $('.choose-us-slider').slick({
            autoplay: false,
            arrows: true,
            slidesToShow: 5,
            infinite: true,
            slidesToScroll: 1,
            responsive: [{
                breakpoint: 1199,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            }
            ]
        });

        $('.product-categorie').slick({
            autoplay: false,
            arrows: true,
            slidesToShow: 3,
            infinite: true,
            slidesToScroll: 1,
            responsive: [{
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
            ]
        });
        $('.pagescroll').slick({
            autoplay: false,
            arrows: true,
            slidesToShow: 1,
            infinite: true,
            slidesToScroll: 1,
            responsive: [{
                breakpoint: 991,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
            ]
        });

        $('.project-slider').slick({
            centerMode: true,
            centerPadding: '550px',
            slidesToShow: 1,
            focusOnSelect: true,
            responsive: [
                {
                    breakpoint: 1600,
                    settings: {
                        centerPadding: '400px',
                    }
                },
                {
                    breakpoint: 1201,
                    settings: {
                        centerPadding: '200px',
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        centerMode: true,
                        centerPadding: '40px',
                        slidesToShow: 1
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        centerMode: true,
                        centerPadding: '40px',
                        slidesToShow: 1
                    }
                }
            ]
        });

        $('.offer-slider').slick({
            autoplay: true,
            arrows: false,
            slidesToShow: 1,
            speed: 1000,
            autoplaySpeed: 4000,
            slidesToScroll: 1,
            fade: true,
        });

        $('.similar-products-slider').slick({
            autoplay: false,
            arrows: true,
            slidesToShow: 3,
            infinite: true,
            slidesToScroll: 1,
            responsive: [{
                breakpoint: 1199,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
            ]
        });

        $('.hut-slider').slick({
            autoplay: true,
            arrows: true,
            dots: true,
            slidesToShow: 1,
            speed: 1000,
            autoplaySpeed: 4000,
            slidesToScroll: 1,
            asNavFor: '.hut-slide-nav'
        });

        $('.hut-slide-nav').slick({
            slidesToShow: 6,
            slidesToScroll: 1,
            asNavFor: '.hut-slider',
            dots: false,
            focusOnSelect: true,
            arrows: false,
        });

        $('.gallery-carousal-desktop').slick({
            rows: 2,
            dots: false,
            arrows: true,
            infinite: true,
            speed: 300,
            slidesToShow: 4,
            slidesToScroll: 4,
            responsive: [
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                    }
                },
            ]
        });

        $('.pro-gallery-slider').slick({
            autoplay: true,
            arrows: true,
            dots: false,
            slidesToShow: 1,
            speed: 1000,
            autoplaySpeed: 4000,
            slidesToScroll: 1,
            sNavFor: '.pro-gallery-slide-nav'
        });

        $('.pro-gallery-slide-nav').slick({
            slidesToShow: 5,
            slidesToScroll: 1,
            asNavFor: '.pro-gallery-slider',
            dots: false,
            focusOnSelect: true,
            arrows: false,
        });

        $('.product-gallery-slider').slick({
            autoplay: true,
            arrows: true,
            dots: false,
            slidesToShow: 1,
            speed: 1000,
            autoplaySpeed: 4000,
            slidesToScroll: 1,
            sNavFor: '.product-gallery-slide-nav'
        });

        $('.product-gallery-slide-nav').slick({
            slidesToShow: 4,
            slidesToScroll: 1,
            asNavFor: '.product-gallery-slider',
            dots: false,
            focusOnSelect: true,
            arrows: false,
        });

    }

    makeProjectSliderCardEqualHeight() {
        var cardHeadingHeight = 0;
        var cardTextHeight = 0;
        var cardImageHeight = 0;
        var cardHeading = '.project-slider .project-block .project-block-body .heading-L2';
        var cardText = '.project-slider .project-block .project-block-body .card-text';
        var cardImage = '.project-slider .project-block .project-block-img';

        $(cardHeading).each(function(){
            if($(this).height() > cardHeadingHeight){
                cardHeadingHeight = $(this).height();
            }
        });
        $(cardHeading).height(cardHeadingHeight);

        $(cardText).each(function(){
            if($(this).height() > cardTextHeight){
                cardTextHeight = $(this).height();
            }
        });
        $(cardText).height(cardTextHeight);

        $(cardImage).each(function(){
            if($(this).height() > cardImageHeight){
                cardImageHeight = $(this).height();
            }
        });
        $(cardImage).height(cardImageHeight);
    }
}

export default Slick;