/*global $*/

class Teams {
    constructor() {
        var _this = this;
        $('.openBtn').on('click',function(){
            
            var member_id = $(this).attr("data-id");
            _this.fetchMemberData(member_id, 'getMemberDetails');
        });
    }

    fetchMemberData(member_id, url) {
        $(".modal-body").html("");
        $.ajax({
            type: 'get',
            url: '/' + url,
            dataType: 'html',
            data: { memberId: member_id },
            processData: true,
            success: function(result) {
                $('.modal-body').html(result);
            },
            error: function() {
                $('.modal-body').html('');
                var errorHtml = '';
                errorHtml += '<div class="col-12 col-md-12 itemFound">';
                errorHtml += '<span>Sorry! No relevant content found for this category</span>';
                errorHtml += '</div>';
                $('.modal-body').html(errorHtml);
            }
        });
        
        $('#myModal').modal({show:true});
        
    }
}

export default Teams;